import type { JourneySelectorViewModel } from "@/models/JourneySelectorViewModel";
import type { TransportModes } from "@/models/basket/TransportModes";
import { getJourneyFromKey } from "@/utilities/GetJourneyFromKey";
// @ts-ignore
import _hasIn from "lodash/hasIn";

export class JourneyUtilities {
    // This method is used to check if a journey, found via it's given JourneyKey, has a specific transport mode (e.g. "Bus"). Returns true if the journey has the specified transport mode, otherwise false.
    static involvesTransportMode(journeyKey: string, mode: TransportModes, journeySelectorViewModel: JourneySelectorViewModel): boolean {
        const journey = getJourneyFromKey(journeyKey, journeySelectorViewModel.allJourneys!);
         if (!_hasIn(journey, 'legs')) return false;
        return journey.legs.some((l) => l.mode === mode);
    }
}
